<template>
  <div>
    <v-container fluid style="padding-bottom: 10vh;">
      <Menubar />
      <v-main>
        <v-row justify="center">
          <v-col cols="12" md="8" lg="6">
            <v-row justify="end" v-if="template.var.sprachen">
              <v-col cols="auto">
                <v-select
                  filled
                  rounded
                  hide-details
                  :items="template.var.sprachen"
                  v-model="sprache"
                  @change="change_language()"
                >
                  <template v-slot:label>
                    <v-img :src="`/lang/${$vuetify.lang.current}.png`"></v-img>
                  </template>
                </v-select>
              </v-col>
            </v-row>
            <v-scroll-x-reverse-transition hide-on-leave>
              <router-view></router-view>
            </v-scroll-x-reverse-transition>
          </v-col>
        </v-row>
      </v-main>
    </v-container>
  </div>
</template>

<script>
import Menubar from './nav'
import store from '../../store'
import { mapGetters } from 'vuex'

export default {
  name: 'Start',
  data() {
    return {
      sprache: 'Deutsch',
      sprache_short: 'de',
    }
  },
  components: {
    Menubar,
  },
  computed: {
    ...mapGetters({
      user: 'user',
      template: 'template',
    }),
  },
  mounted() {
    this.startTimer()
    //this.sprache = this.translate(this.$vuetify.lang.current)
  },
  methods: {
    translate(lang) {
      switch (lang) {
        case 'de':
          return 'Deutsch'
        case 'en':
          return 'English'
        case 'fr':
          return 'Français'
        case 'nl':
          return 'Nederlands'
        default:
          return 'Sprache'
      }
    },
    startTimer() {
      store.dispatch('setTimer')
      setTimeout(() => {
        this.startTimer()
      }, 1000)
    },
  },
}
</script>
